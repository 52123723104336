import React from "react"
import SEO from "../components/seo"
import { FaEnvelope, FaPaperPlane } from "react-icons/fa"

import Image1 from "../assets/img/youMedico/services/Img1.png"
import Image2 from "../assets/img/youMedico/services/Img2.png"
import Image3 from "../assets/img/youMedico/services/Img3.png"
import Image4 from "../assets/img/youMedico/services/Img4.png"
import appendScript from '../utils/appendScript';

import "../scss/services.scss"

const ServicesPage = () => {

  appendScript("https://ma.youpal.se/form/generate.js?id=31", "contact-us-mautic-internal-form");

  return (
    <div className="servicesPage youMedicoMain">
      <SEO title="Services" keywords={[`youpal`, `services`]} />
      <section className="container servicesMain headerContainer">
        <h2>Youmedico’s Solutions</h2>
        <p>
          With state of the art solutions, Youmedico is a pioneer in the field
          of modern and futuristic medical care services
        </p>
      </section>

      <section className="container aboutUsPageSection headerContainer">
        <div className="row">
          <div className="col">
            <img className="sideImg" src={Image1} />
          </div>
        </div>
      </section>

      <section className="container aboutUsPageSection">
        <div className="row">
          <div className="col">
            <img className="sideImgAdv" src={Image2} />
          </div>
          <div className="col sideContent text-left mt-lg-0 mt-5">
            <h2>Telemedicine</h2>
            <p>
            Our telemedicine solution will improve Patient Outcomes and increase revenue. It will also help with the following
            </p>
            <ul>
              <li>
              Managing schedule - Through our web apps, users have full control over their schedules.
              </li>
              <li>
              Defining session length – Users can easily input their preferred session duration and make sure no one will exceed that time limit.
              </li>
              <li>
              Decreasing no-shows – Multiple appointment confirmations and reminders indicating upcoming sessions and minimise no-shows.
              </li>
              <li>
              Secure payments - Besides functional features, we also make sure all your payments are handled securely. Our software uses highly reliable and trusted payment solutions to proceed with online transactions.
              </li>
              <li>
              Setting pricing – Users manage their pricing for telemedicine sessions.
              </li>
              <li>
              Transaction history – Transparency is one of Youmedico's core principles. We offer various online payment options and let users check their billing history whenever they want.
              </li>
              <li>
              Timely follow-ups – Using the power of automation in telemedicine improves users' efficiency. Users can set automatic rules to follow-up with their patients based on health status.
              </li>
              <li>
              Consultation through messaging – In addition to audio and video calls, we also have a message consultation feature.
              </li>
              <li>
              Consultation notes – Users can also take a note during, before, or after the consultation to keep all the details organised and accessible.
              </li>
              <li>
              Database – Our software keeps the medical history of all patients in one place. Thanks to this feature, doctors can advise their patients more efficiently.
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section className="container aboutUsPageSection">
        <div className="row contentAlternate">
          <div className="col-xl-6 sideContent text-left mt-lg-0 mt-5">
            <h2>Remote Patient/Health Monitoring</h2>

            <p>
            Remote patient monitoring (RPM) utilises the digital setup of sensors, IoT-platforms and devices to gather health data from patients. This is then electronically transferred in a secure way to medical professionals in another location for assessment.
            </p>
            <p>
            Our monitoring solutions can gather a broad range of health information from the point of care, such as weight, blood sugar and pressure, oxygen levels in the blood, heart rate, and electrocardiograms.
            </p>
          </div>
          <div className="col-xl-6">
            <img className="sideImg" src={Image3} />
          </div>
        </div>
      </section>

      <section className="container aboutUsPageSection">
        <div className="row">
          <div className="col">
            <img className="sideImg" src={Image4} />
          </div>
          <div className="col sideContent text-left mt-lg-0 mt-5">
            <h2>Global Medical Staff Database</h2>
            <p>
            Our Global Medical Staff Database lists hundreds of doctors specialising in various medical branches. This is available to healthcare providers and patients.
            </p>
          </div>
        </div>
      </section>

      <section className="container">
        <div className="contactBox">
            <h2>Request a demo</h2>
            <div className="sendLinkContainer">
              <div className="formDiv">
                  <FaEnvelope size={25} />
                  {/* Mautic form */}
                  <div id='contact-us-mautic-internal-form'/>
              </div>
            </div>
          </div>
      </section>
    </div>
  )
}

export default ServicesPage
